// components/CallToAction.tsx
import { CallToActionProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';
interface componentProps {
  prop: CallToActionProps;
}

const CallToAction: React.FC<componentProps> = ({prop}) => {
  return (


    <section className="section-call-to-action pd-0">
      <br />
      <br />
      <div className="container">
        <div className="call-to-action style-two bg-primary">
          <div className="row position-relative">
            <div className="col-lg-7 lg-txt-left md-txt-center sm-txt-center xs-txt-center">
              <h4
                className="call-to-title w-700 color-white aos-item"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-once="true"
              >
                {prop.title}
              </h4>
              <h6
                className="sub-title w-400 text-uppercase color-white aos-item"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-once="true"
              >
                {prop.subtitle}
              </h6>
              <p
                className="color-white aos-item"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
              >
                {prop.description}
                </p>
            </div>
            <div
              className="col-lg-5 lg-txt-right md-txt-center sm-txt-center xs-txt-center aos-item xs-mrt-20 sm-mrt-20"
              data-aos="fade-right"
              data-aos-duration="600"
              data-aos-once="true"
            >
              <a className="btn btn-primary btn-white btn-wide rounded-pill" href="contact.html">
              {prop.buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;