// components/TeamBlock.tsx
import { TeamBlockProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';

interface componentProps {
  prop: TeamBlockProps;
}
const TeamBlock: React.FC<componentProps> = ({ prop }) => {
  return (
    <div className="team-block pd-b-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title txt-center">
              <h4
                className="sub-title bg-primary aos-item"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-once="true"
              >
                {prop.textButton}
              </h4>
              <h2
                className="title-main aos-item"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
              >
                {prop.title} <span className="text-gradient">{prop.titleDecorater}.</span>
              </h2>
              <p
                className="description aos-item"
                data-aos="fade-up"
                data-aos-duration="600"
                data-aos-once="true"
              >
                {prop.description}              </p>
            </div>
          </div>
        </div>
        <div className="row team-items-list style-one">
          {/* Team Member 1 */}
          {


            prop.teamMembers.map((member, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                <div className="team-item aos-item" data-aos="fade-up" data-aos-duration={aumentador(index)} data-aos-once="true">
                  <div className="team-thumbnail-area">
                    <figure className="team-thumb">
                      <a className="image" href="team.html">
                        <img src={member.imageUrl} alt="Christin Cox" />
                      </a>
                      <ul className="social-status">
                        <li>
                          <a href="#." className="facebook">
                            <i className="icofont-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#." className="twitter">
                            <i className="icofont-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#." className="linkedin">
                            <i className="icofont-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#." className="instagram">
                            <i className="icofont-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </figure>
                  </div>
                  <div className="team-info txt-center">
                    <h3 className="team-name">
                      <a href="team.html">{member.name}</a>
                    </h3>
                    <p className="designation w-200 color-primary">{member.role}</p>
                  </div>
                </div>
              </div>
            ))


          }

        </div>
      </div>
    </div>
  );
};
function aumentador(index: number) {
  return `${700 * (index + 1)}`;
}


export default TeamBlock;