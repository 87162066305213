// components/Banner.tsx
import { BannerProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';

interface BannerComponentProps {
  banner: BannerProps;
}

const Banner: React.FC<BannerComponentProps> = ({ banner }) => {


  return (

    <>
            <div id="sticky-header" className="active"></div>
      <div className="frontpage-banner-section frontpage-banner-style-three">
        <div className="element-group">
          <div className="element one animation-moving-left-right-three"></div>
          <div className="element two"></div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="frontpage-banner-content">
                <h2
                  className="hero-title w-100 aos-item mrb-0"
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-once="true"
                >
                  {banner.title}
                </h2>
                <h2
                  className="hero-title w-800 aos-item"
                  data-aos="fade-left"
                  data-aos-duration="400"
                  data-aos-once="true"
                >
                  {banner.subtitle}
                  <span className="text-gradient">{banner.sub_subtitle}.</span>
                </h2>
                <div
                  className="hero-desc aos-item"
                  data-aos="fade-left"
                  data-aos-duration="500"
                  data-aos-once="true"
                >
                  <p>   {banner.description}.</p>
                </div>
                <div
                  className="form-group-btn aos-item"
                  data-aos="fade-left"
                  data-aos-duration="600"
                  data-aos-once="true"
                >
                  <a className="btn btn-primary rounded-pill" href="services.html">
                    {banner.textButton}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-1 col-md-6">
              <div className="row align-items-center">
                <div className="col-lg-5 col-6">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <img
                        className="radius-4 aos-item"
                        data-aos="fade-right"
                        data-aos-duration="700"
                        data-aos-once="true"
                        src={banner.images.imageOne}
                        alt="Thumb"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-6" >
                  <img
                    className="radius-4 aos-item"
                    data-aos="fade-left"
                    data-aos-duration="800"
                    data-aos-once="true"
                    src={banner.images.imageTwo}
                    alt="Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};




export default Banner;