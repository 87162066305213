// components/FanfactBlock.tsx
import { FanfactBlockProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';

interface FanfacComponentProps {
  fanFac: FanfactBlockProps;
}

const FanfactBlock: React.FC<FanfacComponentProps> = ({ fanFac }) => {
  return (
    <div className="fanfact-block style-two pd-b-130">
      <div className="container">
        <div className="row fanfact-promo-numbers justify-content-center">
          {
            fanFac.stats.map((stat, index) => (
              <div key={index} className="col-lg-4 col-md-4 col-sm-6">
                <div className="promo-number style-two">
                  <div className="promo-number-inner">
                    <div className="info">
                      <div className="odometer-wrap">
                        <div className="odometer" data-odometer-final={stat.value}>0</div>
                        {stat.suffix}
                      </div>
                      <h4 className="promo-title w-600 text-gradient">{stat.title}</h4>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default FanfactBlock;