// components/PortfolioBlock.tsx
import { PortfolioBlockProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';

interface componentProps {
  prop: PortfolioBlockProps;
}
const PortfolioBlock: React.FC<componentProps> = ({ prop }) => {
  return (
    <div className="portfolio-block style-four ptb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title txt-center">
              <h4
                className="sub-title bg-primary aos-item"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-once="true"
              >
                {prop.titlePresentation}
              </h4>
              <h2
                className="title-main aos-item"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-once="true"
              >
                {prop.title} <span className="text-gradient">{prop.titleDecoration}</span>
              </h2>
              <p
                className="description aos-item"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
              >
                {prop.description}              </p>
            </div>
          </div>
        </div>

        <div className="row portfolio-grid">
          {/* Portfolio Item 1 */}
          {
            prop.portfolioItems.slice(0, 7).map((item, index) => ( 
              <div
                className={`item col-lg-${generarPatron(
                  prop.portfolioItems.length,
                  index
                )} col-md-6 media design aos-item`} data-aos="fade-up"
                data-aos-duration={aumentador(index)}
                key={index}
                data-aos-once="true"
              >
                <div className="portfolio-item">
                  <figure className="portfolio-thumb radius-4">
                    <img src={item.imageUrl} alt="Portfolio Item" />
                    <div className="overlay-wrapper">
                      <div className="overlay overlay-two"></div>
                      <div className="links">
                        <a href={item.popupImageUrl} className="popup-image">
                          <i className="icofont-plus-square"></i>
                        </a>
                      </div>
                      <div className="portfolio-info">
                        <div className="heading-text">
                          <h3 className="heading">
                            <a href="portfolio-creative.html">{item.title}</a>
                          </h3>
                        </div>
                        <div className="cat-text">
                          <p className="cat">{item.category}</p>
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>

            ))
          }



        </div>
      </div>
    </div>
  );
};

function aumentador(index: number) {
  return `${500 * (index + 1)}`;
}

function generarPatron(numero: number, index: number) {
  let patron = [];
  if (numero >= 3) {
    const subPatron = [6, 3, 3];
    for (let i = 0; i < Math.floor(numero / 3); i++) {
      patron.push(...subPatron);
    }
    console.log({ patron, index })
    return patron[index];
  } else {
    patron = [6, 6];
  }
  console.log({ patron, index })


  return patron[index]
}

export default PortfolioBlock;