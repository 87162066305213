// components/WorkBrandBlock.js
import { WorkBrandBlockProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';
interface componentProps {
  prop: WorkBrandBlockProps;
}

const WorkBrandBlock: React.FC<componentProps> = ({ prop }) => {

  return (
    <div className="work-brand-block pd-b-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title txt-center">
              <h4
                className="sub-title bg-primary aos-item"
                data-aos="fade-up"
                data-aos-duration="300"
                data-aos-once="true"
              >
                {prop.textButton}
              </h4>
              <h2
                className="title-main aos-item"
                data-aos="fade-up"
                data-aos-duration="400"
                data-aos-once="true"
              >
                {prop.title} <span className="text-gradient">{prop.titleDecorate}.</span>
              </h2>
              <p
                className="description aos-item"
                data-aos="fade-up"
                data-aos-duration="500"
                data-aos-once="true"
              >
                {prop.description}              </p>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-12">
            <div className="swiper-container brands-carousel-one">
              <div className="swiper-wrapper">
              
                {
                  prop.brands.map((brand, index) => (
                  <div  className="swiper-slide"> 
                      <div key={index} className="brands-link dark-logo" >
                        <img src={brand.imageUrl} alt="logo"/>
                      </div>
                      <div key={index} className="brands-link light-logo" >
                        <img src={brand.imageUrl} alt="logo"/>
                      </div>
                    </div>
                  ))
                }
            
              </div>

              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-12">
            <div className="swiper-container brands-carousel-one">
              <div className="swiper-wrapper">
            

                  {
                  prop.brands.map((brand, index) => (
                  <div key={index} className="swiper-slide"> 
                      <div  className="brands-link dark-logo" >
                        <img src={brand.imageUrl} alt="logo"/>
                      </div>
                      <div key={index} className="brands-link light-logo" >
                        <img src={brand.imageUrl} alt="logo"/>
                      </div>
                    </div>
                  ))
                }
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkBrandBlock;