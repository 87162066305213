// components/Footer.tsx
import { FooterProps } from '@common/admin/appearance/types/appearance-editor-config';
import React from 'react';
interface componentProps {
  prop: FooterProps;
}
const SectionFooter: React.FC<componentProps> = ({ prop }) => {
  return (
    <footer className="site-footer style-one">
      {/* Footer Widget Area */}
      <div className="footer-widget-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-7 col-md-7 col-12 aos-item" data-aos="fade-up" data-aos-duration="300" data-aos-once="true">
              <div className="footer-widget-top md-txt-left lg-txt-left xs-txt-center sm-txt-center">
                <h2 className="footer-widget-title-sm color-primary">{prop.contactTitle}</h2>
                <h4 className="footer-widget-title">{prop.contactSubtitle}</h4>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-12 aos-item" data-aos="fade-up" data-aos-duration="400" data-aos-once="true">
              <div className="footer-widget-btn md-txt-right lg-txt-right xs-txt-center sm-txt-center">
                <a className="btn btn-primary rounded-pill btn-lg" href={prop.contactButton.link}>{prop.contactButton.text}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom Area */}
      <div className="footer-bottom-area style-one">
        <div className="container">
          <div className="footer-copyright">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-5 col-md-5 col-12 aos-item" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">
                <div className="footer-copyright-text md-txt-left lg-txt-left xs-txt-center sm-txt-center">
                  <p>© {obtenerAno()} <span className="color-primary">{prop.copyright.companyName}</span>, All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-7 col-12 aos-item" data-aos="fade-up" data-aos-duration="600" data-aos-once="true">
                <div className="footer-copyright-nav md-txt-right lg-txt-right xs-txt-center sm-txt-center md-mrt-0 xs-mrt-20 sm-mrt-20">
                  {
                    prop.links.map((link, index) => (
                      <a key={index} href={link.href}>{link.text}</a>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
          {/* Footer Logo and Social Links */}
          <div className="footer-copyright-logo-area">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-4 col-md-4 col-12 aos-item" data-aos="fade-up" data-aos-duration="700" data-aos-once="true">
                <div className="footer-copyright-logo mrt-30 md-txt-left lg-txt-left xs-txt-center sm-txt-center">
                  <a href="creative-agency.html">
                    <img src={prop.logo.imageUrl} alt="Logo" />
                  </a>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-12 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
                <div className="footer-copyright-social md-txt-right lg-txt-right xs-txt-center sm-txt-center md-mrt-0 mrt-30">
                  {
                    prop.socialLinks.map((social, index) => (
                      <a key={index} href={social.href}><i className={social.iconClass}></i></a>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

function obtenerAno() {
  const fechaActual = new Date();
  return fechaActual.getFullYear();
}

export default SectionFooter;